import { ChangeEvent, FC, FormEvent, MouseEvent, useState } from 'react';
import block from 'utils/bem-css-module';
import { emailRegExp } from 'utils/user';

import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { InternalLink } from 'components/Link';
import { SocialBlock } from 'components/SocialBlock';

import { AgreementCheckbox } from './agreement-checkbox';
import { Or } from './or';

import style from './before-login-popup.scss';


type RegistrationForm = {
	email: string;
	pass: string;
	agree: boolean;
}

const b = block(style);

export const Registration: FC = () => {
	const [form, setForm] = useState<RegistrationForm>({
	    email: '',
	    pass: '',
	    agree: false,
	});

	const [error, setError] = useState('');
	const [fetching, setFetching] = useState(false);

	const submit = async (e: MouseEvent | FormEvent): Promise<void> => {
	    e.preventDefault();
	    if (!emailRegExp.test(form.email)) {
	    	setError('Введите настоящую почту');
			return;

		}
		const data = new FormData();
	    data.append('login', form.email);
	    data.append('password', form.pass);
	    setFetching(true);

	    try {
			const response = await fetch('/api/v1/user/register/', {
				credentials: 'same-origin',
				method: 'POST',
				body: data,
			});

			if (response.status === 200) {
				setError('');
				// @ts-ignore
				window.location = '/';
				return;
			}
			if (response.status === 409) {
				setError('Такой пользователь уже существует');
				return;
			}
			if (response.status === 400) {
				setError('Пароль должен быть от 6 до 32 и не совпадать с логином');
				return;
			}
			setError('Попробуйте еще раз');
	    } catch (err) {
			setError('Попробуйте еще раз');
	    } finally{
			setFetching(false);
		}
	};

	const changeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    	setForm({ ...form, email: e.target.value });
    	setError('');
	};

	const changePassword = (e: ChangeEvent<HTMLInputElement>): void => {
		setForm({ ...form, pass: e.target.value });
		setError('');
	};


	return (
		<>
			<Heading
				size='l'
				level={1}
				className={b('heading', { hidden: true })}
				id='before-login-modal-id'
			>
				Регистрация
			</Heading>
			<form className={b('form')} onSubmit={submit} id='before-login-modal-body'>
				{error !== '' && <p className={b('margin').mix('alert-error')}>{error}</p>}
				<Input
					type='email'
					value={form.email}
					onChange={changeEmail}
					label='Ваша почта:'
					autoComplete='on'
					className={b('input')}
					id='registration-email-input'
					required={false}
				/>
				<Input
					type='password'
					value={form.pass}
					onChange={changePassword}
					label='Ваш пароль:'
					placeholder='Oт 6 до 32 символов'
					autoComplete='new-password'
					className={b('input')}
					id='registration-password-input'
					required={false}
				/>
				<AgreementCheckbox
					onClick={() => setForm({ ...form, agree: !form.agree })}
					checked={form.agree}
					className={b('checkbox')}
					id='registation-checkbox'
					size='s'
				/>
				<Button
					buttonType='success'
					disabled={(form.email === '') || (!form.agree) || (form.pass === '')}
					onClick={submit}
					loading={fetching}
					size='m'
					className={b('button')}
				>
					Зарегистрироваться
				</Button>
				<InternalLink url='/login/' className={b('link')}>Есть аккаунт?</InternalLink>
				<div className={b('social')}>
					<Or />
					<SocialBlock />
				</div>
			</form>
		</>
	);
};

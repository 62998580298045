import { FC, FormEvent, MouseEvent, useState } from 'react';
import block from 'utils/bem-css-module';
import { post } from 'utils/fetch';

import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { InternalLink } from 'components/Link';
import { SocialBlock } from 'components/SocialBlock';

import { Or } from './or';

import style from './before-login-popup.scss';


const b = block(style);

export const Login: FC = () => {
	const [form, setForm] = useState({
		email: '',
		pass: '',
		error: '',
		fetching: false,
		openRecovery: false,
	});

	const onSubmit = async (e: FormEvent<HTMLFormElement> & MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setForm({ ...form, fetching: true });
		try {
			const response = await post('/api/v1/user/login/', { login: form.email, password: form.pass });
			if (response.ok) {
				setForm({ ...form, error: '' });
				// @ts-ignore
				window.location = '/';
				return;
			}
			setForm({ ...form, error: 'Error during fetching!!', fetching: false });
		} catch (err) {
			setForm({ ...form, error: err, fetching: false });
		}
	};

	return (
		<>
			<Heading
				size='l'
				level={1}
				className={b('heading', { hidden: true })}
				id='before-login-modal-header'
			>
				Вход
			</Heading>
			<form className={b('form')} onSubmit={onSubmit} id='before-login-modal-body'>
				{form.error && <p className={b('margin').mix('alert-error')}>
					Пароль или логин не правильные
				</p>}
				<Input
					type='email'
					value={form.email}
					onChange={(e) => setForm({ ...form, email: e.target.value })}
					label='Логин или почта:'
					autoComplete='on'
					className={b('input')}
					id='login-email-input'
					required={false}
				/>
				<Input
					autoComplete='on'
					type='password'
					value={form.pass}
					onChange={(e) => setForm({ ...form, pass: e.target.value })}
					label='Ваш пароль:'
					className={b('input')}
					id='login-password-input'
					required={false}
				/>
				<Button
					buttonType='success'
					onClick={onSubmit}
					className={b('button')}
					loading={form.fetching}
					type='submit'
					size='m'
				>
					Войти
				</Button>
				<div className={b('links')}>
					<InternalLink url='/recovery' className={b('link')}>Забыли пароль?</InternalLink>
					<InternalLink url='/regist/' className={b('link')}>В первый раз?</InternalLink>
				</div>
				<div className={b('social')}>
					<Or />
					<SocialBlock />
				</div>
			</form>
		</>
	);
};
